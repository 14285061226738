import 'focus-visible'
import '@/styles/tailwind.css'

import { Wrapper } from '@googlemaps/react-wrapper'
import { AppProps } from 'next/app'

export default function App({ Component, pageProps }: AppProps) {
  return (
    <Wrapper
      apiKey={process.env.NEXT_PUBLIC_MAPS_API_KEY!}
      libraries={['places']}
    >
      <Component {...pageProps} />
    </Wrapper>
  )
}
